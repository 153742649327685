import { useCallback } from 'react';

import { InvoiceID } from '../../invoicesTypes';

import { InvoiceBffUrl } from '../../InvoiceBffUrl';
import { downloadFileFromBffApi } from '../../../../utils/downloadFileFromBffApi';

export type DownloadInvoiceInput = {
  invoiceId: InvoiceID;
  overdueView?: boolean;
};

function useDownloadReceiptInvoicePdf() {
  const downloadReceiptInvoice = useCallback<
    (input: DownloadInvoiceInput) => void
  >((input) => {
    const params = new URLSearchParams();

    input.overdueView &&
      params.append('overdueView', input.overdueView.toString());

    const url = InvoiceBffUrl.receiptPdf(input.invoiceId);

    downloadFileFromBffApi(`${url}?${params}`);
  }, []);

  return { downloadReceiptInvoice };
}

export default useDownloadReceiptInvoicePdf;
